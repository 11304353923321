import type {
  CartItem,
  CartOption,
  ProductOption,
} from "@local/product-option-manager/types"
import {
  getCartOptionsByVariantOptions,
  getFirstVariantInStock,
  getRegionalVariantFromVariant,
  getVariantByVariantOptions,
  getVariantOptionsByCartOptions,
} from "@local/product-option-manager/utils"
import { useEffect, useId, useMemo, useRef, useState } from "react"
import type { Payload } from "@local/payload-client/src/types"
import { useI10n } from "@local/i10n"
import { OptionsPickerServer } from "./OptionsPicker"
import { StylePickerServer } from "./OptionsPicker/StylePicker"
import { SlotOutput, SlotPricesTable } from "../fetchers"
import { Carousel, SwiperRef } from "../Carousel"
import {
  reducePossibleOptions,
  useCarouselDataParser,
} from "@local/utils/src/useCarouselDataParser"

interface ProductProps {
  checkVariantStock: (productId: string, variantId: string) => boolean
  displayName?: boolean
  handleChange: (
    cartItem: Omit<CartItem, "price" | "basePrice">,
    key?: number
  ) => void
  item: CartItem
  getOptions: (productId: string) => ProductOption[]
  stylesMap: Map<string, Payload.Product>
  slotData: SlotOutput
  handleQuantityChange: (quantity: number) => void
  quantity: number
  index: number
  carouselSelectedItem: number
  setCarouselSelectedItem: (index: number) => void
  slotPrices: SlotPricesTable
}

interface ProductOptionOptionValue {
  optionName: string
  valueName: string
}

export const Product = ({
  handleQuantityChange,
  checkVariantStock,
  displayName,
  handleChange,
  item,
  getOptions,
  stylesMap,
  slotData,
  quantity,
  index,
  carouselSelectedItem,
  setCarouselSelectedItem,
  slotPrices,
}: ProductProps) => {
  const { country: countryCode } = useI10n()
  const uid = useId()

  const isDiscountPercentage = slotData.products.length > 1 ? true : false

  const {
    carouselImages,
    variantImagesArray,
    carouselOptionImages,
    productImages,
  } = useCarouselDataParser(
    (slotData.products.find(
      (p) => p.id === item.productId
    ) as Payload.Product) ?? slotData.products[0]
  )
  const carouselRef = useRef<SwiperRef>(null)
  const [currentSelection, setCurrentSelection] = useState(
    [] as ProductOptionOptionValue[]
  )

  const currentCarouselImages = useMemo(() => {
    const selectedOptionImages = []
    const selectedLifestyleImages = []

    if (currentSelection.length > 0) {
      if (carouselOptionImages) {
        const selected = carouselOptionImages.filter((optionImages) => {
          return currentSelection.find((item) => {
            return item.valueName === optionImages.variantName
          })
        })
        selectedOptionImages.push(...selected)
      }

      if (productImages && currentSelection.length > 0) {
        const selected = productImages.map((img, i) => {
          return {
            itemProps: {
              alt: "",
              url: img?.image.url || "",
            },
            key: `product-image-${i}-${img?.id}`,
            position: currentSelection.length + i,
          }
        })
        selectedLifestyleImages.push(...selected)
      }
    }

    const selected = [...selectedOptionImages, ...selectedLifestyleImages]
    return selected.length > 0 ? selected : carouselImages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelection, item])

  useEffect(() => {
    carouselRef?.current?.swiper?.slideTo(carouselSelectedItem, 0)
    item && setCurrentSelection(item.options)
  }, [carouselSelectedItem, item])

  const carouselItemUpdater = (item: any) => {
    setCurrentSelection(item.options)

    const reduced = reducePossibleOptions(item.options)

    if (carouselOptionImages.length <= 0) {
      const selectedImage = variantImagesArray?.find((image) =>
        image.possibleOptions.find(
          (option) => JSON.stringify(reduced) === JSON.stringify(option)
        )
      )
      const carouselIndexToGoto = carouselImages?.findIndex(
        (image) => image.variantImageId === selectedImage?.id
      )

      if (carouselIndexToGoto > -1) {
        setCarouselSelectedItem(carouselIndexToGoto)
      }
    }
  }

  const product = stylesMap.get(item.productId) as Payload.Product
  if (!product) {
    return null
  }
  const options = getOptions(product?.id)

  const productHasOptions =
    options &&
    options.length > 0 &&
    options.find((o) => o.values && o.values.length > 1)

  const handleOptionChange = (
    optionName: string,
    valueName: string,
    localizedName: string
  ) => {
    const option = options.find((o) => o.name === optionName)

    const optionValue = option?.values.find((v) => v.name === valueName)
    if (!option || !optionValue) {
      return
    }
    const cartOptions: CartOption[] = item.options.map((o) =>
      o.optionName === option.name
        ? {
            optionId: option.id,
            optionName: option.name,
            valueId: optionValue.id,
            valueName: optionValue.name,
            localizedName: localizedName,
          }
        : o
    )

    const variant = getRegionalVariantFromVariant(
      getVariantByVariantOptions(
        getVariantOptionsByCartOptions(cartOptions, options),
        product?.variants?.filter((variant) => variant.active)
      ),
      countryCode
    )

    if (variant) {
      const changedItem = {
        cartKey: item.cartKey,
        options: cartOptions,
        productId: product.id,
        productName: item.productName,
        preOrder: variant.preorder,
        sku: variant.sku,
        quantity: 1,
        variantId: variant.id,
        variantName: variant.name,
      }
      handleChange(changedItem)
      carouselItemUpdater(changedItem)
      setCarouselSelectedItem(0)
    }
  }

  const handleUpperOptionChange = (variant: Payload.ProductVariant) => {
    const regionalVariant = getRegionalVariantFromVariant(variant, countryCode)
    if (!regionalVariant) {
      return
    }
    const cartOptions = getCartOptionsByVariantOptions(
      options,
      regionalVariant.options,
      item.options
    )

    if (cartOptions) {
      const changedItem = {
        cartKey: item.cartKey,
        options: cartOptions,
        preOrder: variant.preorder,
        productId: product.id,
        productName: item.productName,
        sku: regionalVariant.sku,
        quantity: 1,
        variantId: regionalVariant.id,
        variantName: regionalVariant.name,
      }
      handleChange(changedItem)
      carouselItemUpdater(changedItem)
      setCarouselSelectedItem(0)
    }
  }

  const handleProductChange = (productId: string, key?: number) => {
    const product = stylesMap.get(productId)
    const cartItem = getFirstVariantInStock({
      product: product as Payload.Product,
      region: countryCode,
    })
    if (cartItem) {
      const changedItem = {
        cartKey: item.cartKey,
        ...cartItem,
      }
      handleChange(changedItem, key)
      carouselItemUpdater(changedItem)
      setCarouselSelectedItem(0)
    }
  }

  let variantImage: Payload.Media
  const variant = product?.variants?.find((v) => v.name === item.variantName)
  if (variant?.image) {
    variantImage = variant.image
  } else {
    product.options?.forEach((option) => {
      const selectedOption = item.options.find(
        (o) => o.optionName === option.name
      )
      if (selectedOption) {
        const value = option.values.find(
          (v) => v.name === selectedOption.valueName
        )
        if (value?.images && !variantImage) {
          variantImage = value.images[0]?.image
        }
      }
    })
  }
  if (!variantImage!) {
    variantImage = product.images?.[0].image
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <div className="w-full">
        {quantity > 1 ? (
          <div className="flex flex-row items-center justify-between mb-2">
            <p>
              <b>Item #{index + 1}</b>
            </p>
            {index + 1 === quantity ? (
              <button
                className="text-xs text-indigo-500 underline cursor-pointer"
                onClick={() => handleQuantityChange(quantity - 1)}
              >
                Remove
              </button>
            ) : null}
          </div>
        ) : null}
        {displayName && (
          <div className="flex flex-row items-center">
            <p>
              Item #{index}: {item.productName}
            </p>
          </div>
        )}
        <div className="relative">
          <Carousel
            mini
            animate
            bulletsOutside
            refObj={carouselRef}
            carouselImages={currentCarouselImages}
            navigation
            setCarouselSelectedItem={setCarouselSelectedItem}
          />
        </div>
        {item.options.length > 0 && productHasOptions ? (
          <div>
            {isDiscountPercentage && stylesMap.size > 1 ? (
              <StylePickerServer
                data={stylesMap}
                selectedProductId={item.productId}
                handleChange={handleProductChange}
                slotPrices={slotPrices}
              />
            ) : null}
            {options.map((option, index) =>
              option.name === "Style" ? null : (
                <OptionsPickerServer
                  key={`${uid}-${option.name}-${index}`}
                  options={options}
                  index={index}
                  product={product}
                  selectedCartOptions={item.options as CartOption[]}
                  handleChange={handleOptionChange}
                  handleUpperOptionChange={handleUpperOptionChange}
                  checkVariantStock={checkVariantStock}
                />
              )
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}
