"use client"

import Link from "next/link"

export const CheckoutBlocker = () => {
  return (
    <div className="fixed inset-0 overflow-y-auto box-border z-[99999] bg-gray-500 bg-opacity-75 backdrop-blur-sm p-3 pb-0 w-[100vw] h-[100vh] flex justify-center items-center">
      <span className="text-lg font-bold text-center text-white">
        Please wait while we redirect you to your order page <br /> or{" "}
        <Link href="/order/confirm/" className="text-indigo-600 underline">
          Click Here
        </Link>
      </span>
    </div>
  )
}
