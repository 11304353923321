"use client"
import { FaqItem } from "./FaqItem"

const questions = [
  {
    question: "When will my order arrive?",
    answer:
      "From the moment you checkout to the second your package arrives at your door, it takes around 10 to 14 days. Items pre-ordered will be shipped on the date indicated on the page and checkout, and no sooner than that.",
  },
  {
    question: "Can I return my product?",
    answer:
      "Yes, of course! We have a standard 60-day return policy. However, if you made the purchase between October 1st and December of the same year, the return and exchange window extends to 31st January the following year. For any more questions, please check our full Ts&Cs.",
  },
]

export const FaqCheckout = () => {
  return (
    <div className="flex flex-col gap-4 mx-6 mb-6 lg:px-1 md:mt-8">
      <div className="lg:py-2">
        <p className="text-sm font-bold uppercase">Call us</p>
        <p className="text-base">+1 769 223 7756</p>
      </div>
      <div className="w-full py-2 mx-auto text-center lg:py-2">
        <h2 className="flex justify-between w-full text-lg font-bold tracking-tight border-b-2 border-black">
          FAQ
        </h2>
        <div className="mt-4 space-y-6">
          {questions.map((faq, index) => (
            <FaqItem
              data={faq}
              index={index}
              totalItems={questions.length}
              key={`${faq.question}-${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
