"use client"

import { useOrder } from "@local/order/src/useOrder"
import Cookies from "js-cookie"
import { useCallback, useEffect, useState } from "react"
import { useRouter } from "next/navigation"
import ErrorBoundary from "./ErrorBoundary"
import UpsellClient from "@local/upsell/src"
import { PlaceholderClient } from "@local/upsell/src/Placeholder"
import { trackPurchase } from "@local/tracking/src/purchase"
import { useCart } from "@local/cart/src/useCart"
import { useI10n } from "@local/i10n"
import useGiftCart from "@local/cart/src/useGiftCart"
import type { UpsellOutput } from "@local/upsell/src/fetchers"

export const UpsellPopupClient = ({
  channel,
  method = "stripe",
  modalRef,
  prefetchedData,
  setBlockCheckout,
}: {
  channel: string
  method?:
    | "stripe"
    | "paypal"
    | "primer"
    | "flex"
    | "klarna"
    | "hyper"
    | "card"
    | string
    | null
  modalRef?: React.MutableRefObject<HTMLDivElement | null>
  prefetchedData?: UpsellOutput
  setBlockCheckout?: (block: boolean) => void
}) => {
  const { orders, addOrder } = useOrder()
  const router = useRouter()
  const cart = useCart()
  const { bonusItems, clearGiftCart } = useGiftCart()
  const { currencyCode } = useI10n()

  const order = orders?.[0] || null

  const [methodState, setMethodState] = useState(
    method ? method : (order?.method ?? "")
    //TODO: send to sentry as error?
    //TODO: sentry.captureexcepotion
  )
  const [isValidMethod, setIsValidMethod] = useState(false)

  const gotoOrder = async () => await router.push(`/order/confirm/`)

  const showOrder = () => {
    setUpsellFinished(true)
    Cookies.remove("show-upsell-modal")
    document.body.style.overflow = "auto"
    setBlockCheckout && setBlockCheckout(true)
    router.push(`/order/confirm/`)

    gotoOrder()
      .then(() => {})
      .catch(() => router.push(`/order/confirm/?blank=true`))
  }

  const run = useCallback(async () => {
    if (method === "flex") {
      trackPurchase({
        cart,
        currencyCode,
      })
    }

    await cart
      .convertToOrder({
        bonusItems,
        currency: currencyCode,
        method,
        cb: addOrder,
      })
      .then(() => {
        clearGiftCart()
      })
      .catch((e) => {
        console.error("UpsellPopup: ", e)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOrder, cart, method, currencyCode])

  useEffect(() => {
    setMethodState(method ? method : (order?.method ?? ""))
    const isCard = methodState.toUpperCase().indexOf("CARD") > -1
    const isPayPal = methodState.toUpperCase().indexOf("PAYPAL") > -1
    const isStripe = methodState.toUpperCase().indexOf("STRIPE") > -1
    const isKlarna = methodState.toUpperCase().indexOf("KLARNA") > -1
    const isHyper = methodState.toUpperCase().indexOf("HYPER") > -1
    setIsValidMethod(isCard || isPayPal || isStripe || isKlarna || isHyper)

    if (methodState === "invalid") {
      console.error("UpsellPopup: order is empty or paymentMethod is invalid")
      showOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, methodState])

  useEffect(() => {
    method && run()
    router.prefetch("/order/confirm/")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [upsellFinished, setUpsellFinished] = useState(false)

  return (
    <ErrorBoundary>
      {!upsellFinished ? (
        <UpsellClient
          channel={channel}
          isValidMethod={isValidMethod}
          items={cart.items}
          modalRef={modalRef}
          orders={orders}
          setShowOrder={showOrder}
          prefetchedData={prefetchedData}
        />
      ) : (
        <PlaceholderClient finished />
      )}
    </ErrorBoundary>
  )
}
