import { AvailableCurrencies, Currency } from "@local/i10n"
import fetchPayload, {
  fetchPayloadLocal,
} from "@local/payload-client/src/fetchPayload"
import type { Payload } from "@local/payload-client/src/types"
import * as Sentry from "@sentry/nextjs"
import { defaultUpsell } from "./defaultUpsell"

interface Props {
  channel: string
  typeId: "single" | "multi"
  productId?: string | null
}

export const getUpsellFromPayload = async ({
  channel,
  typeId,
  productId,
}: Props) => {
  const channelToUse = channel === "baerskin-hoodie-4" ? "hoodie4" : channel

  const req = await fetchPayloadLocal<Payload.Upsell>([
    `/upsells/get/${channelToUse}/${typeId}/${productId ? `${productId}/` : ""}`,
    // "/upsells/get/baerskintactical/multi/651aaa82b72392c9ca340557/",
    // I'll leave this here for when we need to "force" something on upsell
    "en",
  ])

  return req
}

interface Input {
  channel: string
  productId?: string | null
  type: "single" | "multi"
}

type UpsellPrice = {
  amount: number
  discount: number
}

type UpsellPriceMap = Map<Currency, UpsellPrice>

export type DiscountType = "priced" | "discount"

export type SlotOutput = {
  description?: string | null
  discountType: DiscountType
  fullPrices: Map<Currency, number>
  id: number
  prices: UpsellPriceMap
  products: Payload.Product[]
  title: string
  upsellId: string
  version: number
}

export interface UpsellOutput {
  slots: Array<SlotOutput>
  type: "single" | "multi"
  isError: boolean
}

export type SlotPrices = {
  price: number
  origPrice: number
}

export type PricesMap = Map<string, SlotPrices>

export interface SlotPricesTable {
  discount: number
  prices: PricesMap
  discountType: "percent" | "amount"
}

export const getUpsell = async ({
  channel,
  productId,
  type,
}: Input): Promise<UpsellOutput> => {
  try {
    // Figure out the current channel

    if (!channel || !type) {
      throw new Error("Missing required variables")
    }

    // Fetch the upsell from the API
    const selectedFunnel =
      (await getUpsellFromPayload({
        channel,
        typeId: type,
        productId,
      })) ?? defaultUpsell

    if (!selectedFunnel) {
      throw new Error("No upsells found")
    }

    const slotsToParse = []
    const slots: SlotOutput[] = []

    if (selectedFunnel.slots?.one) {
      slotsToParse.push(selectedFunnel.slots?.one)
    }
    if (selectedFunnel.slots?.two) {
      slotsToParse.push(selectedFunnel.slots?.two)
    }
    if (selectedFunnel.slots?.three) {
      slotsToParse.push(selectedFunnel.slots?.three)
    }

    // Assemble the slots
    for (let i = 0; i < slotsToParse.length; i++) {
      const prices: UpsellPriceMap = new Map()
      const fullPrices: Map<Currency, number> = new Map()
      const slot = slotsToParse[i]
      const product = slot.product
      const styles = slot.productStyles
      const hasStyles = slot.productStyles && slot.productStyles.length > 0
      const products = [product]

      if (!product) {
        throw new Error("Missing product")
      }

      const title = slot.content?.title ?? product.name
      const description =
        slot.content?.description ?? product.shortDescription ?? null

      // Get full price in all currencies
      fullPrices.set(Currency.USD, product.origPrice)
      product.origPrices?.forEach((item) => {
        fullPrices.set(item.currency, item.price)
      })

      // Calculate price
      let discount = slot.discount || 1500

      if (product.prices) {
        const sortedPrices = product.prices?.sort(
          (a, b) => (a.minQuantity as number) - (b.minQuantity as number)
        )[0]

        // USD Less price
        fullPrices.set(Currency.USD, Math.round(sortedPrices.price * 100))

        AvailableCurrencies.forEach((cur) => {
          const price = Math.round((sortedPrices[cur] as number) * 100)
          fullPrices.set(cur, price)
        })
      }

      // First USD
      const fullPriceUsd = fullPrices.get(Currency.USD) as number
      const priceUsd = Math.round(
        (fullPriceUsd * (100 - (slot.discount as number))) / 100
      )
      prices.set(Currency.USD, {
        amount: priceUsd,
        discount,
      })

      // Now All Currencies
      AvailableCurrencies.forEach((cur) => {
        const fullPrice = fullPrices.get(cur) as number
        const price = Math.round(
          (fullPrice * (100 - (slot.discount as number))) / 100
        )
        prices.set(cur, {
          amount: price,
          discount,
        })
      })

      let discountType: DiscountType = "discount"

      // Calculate Priced Upsells
      if (slot.pricing === "priced" && !hasStyles) {
        discountType = "priced"

        // In USD
        const priceOnCurrency = slot.prices?.find(
          (item) => item.currency === Currency.USD
        )

        const fullPrice = fullPrices.get(Currency.USD) as number

        const price =
          priceOnCurrency && priceOnCurrency.amount
            ? Math.round(priceOnCurrency.amount * 100)
            : null

        if (price) {
          const discount = Math.round((1 - price / fullPrice) * 100)

          prices.set(Currency.USD, {
            amount: price,
            discount,
          })
        }

        // In all currencies
        AvailableCurrencies.forEach((cur) => {
          const priceOnCurrency = slot.prices?.find(
            (item) => item.currency === cur
          )
          const fullPrice = fullPrices.get(cur) as number
          const price =
            priceOnCurrency && priceOnCurrency.amount
              ? Math.round(priceOnCurrency.amount * 100)
              : null

          if (price) {
            const discount = Math.round((1 - price / fullPrice) * 100)

            prices.set(cur, {
              amount: price,
              discount,
            })
          }
        })
      }

      // Add Styles so we can be stylish
      if (hasStyles && styles) {
        products.push(...styles)
      }

      const slotData = {
        description,
        discountType,
        fullPrices,
        id: i + 1,
        prices,
        products,
        title,
        upsellId: selectedFunnel.id,
        version: slot.version ?? 0,
      }

      // Send this to the built thing
      slots.push(slotData)
    }

    return { slots, type, isError: false }
  } catch (e) {
    console.error(e)
    Sentry.captureException(`Upsell error: fetch error - ${e}`)
    return { slots: [], type, isError: true }
  }
}

export const getAllUpsells = async (channel: string) => {
  const Single = getUpsell({
    channel,
    type: "single",
  })
  const Multi = getUpsell({
    channel,
    type: "multi",
  })

  const allData: Array<UpsellOutput> = await Promise.all([Single, Multi])

  return allData
}

interface ByIdProps {
  id: string
}

export const getUpsellByIdFromPayload = async ({ id }: ByIdProps) => {
  const allUpsells = await fetchPayload<Payload.Upsell>([
    `/upsells/${id}/`,
    "en",
  ])
  return allUpsells
}
