"use client"

import type { RefObject, Dispatch, SetStateAction } from "react"
import { useId, useState } from "react"
import { type carouselImageType } from "@local/utils/src/useCarouselDataParser"
import { Swiper, type SwiperClass, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import styles from "./styles.module.css"
import Image from "next/image"
import { classNames } from "@local/utils"
import { BunnyLoader } from "@local/utils/src/bunnyLoader"

export interface SwiperRef extends React.HTMLAttributes<HTMLElement> {
  swiper: SwiperClass
}

export const Carousel = ({
  animate = false,
  mini = false,
  refObj,
  startingSlide,
  navigation = false,
  setCarouselSelectedItem,
  carouselImages,
  onClick,
  bulletsOutside,
  uniqueId,
}: {
  animate?: boolean
  mini?: boolean
  refObj?: RefObject<SwiperRef | null>
  startingSlide?: number
  navigation?: boolean
  setCarouselSelectedItem: (index: number) => void
  carouselImages: carouselImageType[]
  onClick?: Dispatch<SetStateAction<boolean>>
  bulletsOutside?: boolean
  uniqueId?: string
}) => {
  const [carouselClicked, setCarouselClicked] = useState(false)
  const id = useId()

  return (
    <>
      <Swiper
        initialSlide={startingSlide ?? 0}
        ref={refObj}
        onSlideChange={(e) => {
          setCarouselSelectedItem && setCarouselSelectedItem(e.activeIndex)
        }}
        grabCursor={true}
        spaceBetween={mini ? 0 : 5}
        pagination={{
          clickable: mini ? false : true,
          dynamicBullets: mini ? true : false,
          el: bulletsOutside
            ? `#swiper-custom-pagination-${uniqueId ? uniqueId : ""}`
            : "",
        }}
        rewind={true}
        navigation={navigation}
        modules={[Pagination, Navigation]}
        className="w-full max-w-full max-h-full pb-10 aspect-square"
        wrapperClass={!animate || carouselClicked ? "" : styles.bounce}
        style={{
          // @ts-ignore-next-line
          "--swiper-pagination-color": "#FFFFFF",
          "--swiper-navigation-color": "#AAAAAA",
        }}
        onClick={() => !mini && setCarouselClicked(true)}
      >
        {carouselImages?.map((slide, i) => (
          <SwiperSlide
            key={`${id}-${slide.key}-${i}`}
            onDragStart={() => setCarouselClicked(true)}
            onMouseDown={() => setCarouselClicked(true)}
            onClick={() => {
              onClick && onClick(true)
            }}
          >
            <Image
              src={slide.itemProps.url ? slide.itemProps.url : "/notfound.svg"}
              fill
              sizes="(max-width: 1200px) 100vw"
              alt={slide.itemProps.alt ?? `${name} Image #${i}`}
              loader={
                process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {bulletsOutside && (
        <div
          className={classNames(
            "h-6 mx-auto mt-2 w-fit",
            mini ? "-mb-2" : "mb-2"
          )}
        >
          <div
            id={`swiper-custom-pagination-${uniqueId ? uniqueId : ""}`}
            className={classNames("mx-auto", mini ? "!absolute" : "")}
            style={{
              // @ts-ignore-next-line
              "--swiper-pagination-color": "#000000",
              "bottom": "unset",
            }}
          />
        </div>
      )}
    </>
  )
}
