import { useEffect, useState, useRef, useMemo, MutableRefObject } from "react"
import {
  PaymentMethodType,
  Primer,
  type PrimerCheckout,
} from "@primer-io/checkout-web"
import { fetchApi } from "@local/utils/src/fetchApi"
import { useCart } from "@local/cart/src/useCart"
import { useI10n } from "@local/i10n/src/useI10n"
import { Primer as TPrimer } from "../helpers/primerTypes"
import { sleep } from "@local/utils/src/sleep"
import { getPrimerSessionBody } from "@local/checkout/src/helpers/getPrimerSessionBody"
import { useGiftCart } from "@local/cart/src/useGiftCart"
import { useIP } from "@local/tracking/src/useIP"
import { trackAction } from "@local/tracking/src/trackActions"

type Props = TPrimer.HookProps & { slug?: string | null }

export const usePrimer = ({
  email,
  phone,
  currencyCode,
  shippingAddress,
  onCheckoutComplete,
  onCheckoutFail,
  slug,
}: Props) => {
  const { locale } = useI10n()
  const cart = useCart()
  const giftCart = useGiftCart()
  const { isp } = useIP()
  const [clientToken, setClientToken] = useState<string | null>(null)
  const [universalCheckout, setUniversalCheckout] =
    useState<PrimerCheckout | null>(null)

  const sessionRef = useRef(false) // To avoid creating two primer sessions on dev
  const checkoutRef = useRef(false) // To avoid double rendering of checkout on dev
  const paymentMethod: MutableRefObject<PaymentMethodType | null> = useRef(null)

  const filteredBonusItems = useMemo(
    () => giftCart.bonusItems?.filter((item) => item?.giftAccepted),
    [giftCart]
  )

  const sessionBody = useMemo(
    () =>
      getPrimerSessionBody({
        cart,
        clientToken,
        currencyCode,
        email,
        giftCart: {
          ...giftCart,
          bonusItems: filteredBonusItems,
        },
        isp,
        locale,
        phone,
        slug,
        shippingAddress,
      }),
    [
      cart,
      clientToken,
      currencyCode,
      email,
      filteredBonusItems,
      giftCart,
      isp,
      locale,
      phone,
      shippingAddress,
      slug,
    ]
  )

  const createPrimerSession = async () => {
    // To avoid creating two primer sessions on dev
    // Without this a bug is actually created blocking the session update on coupon change.
    if (sessionRef.current) {
      return
    } else {
      sessionRef.current = true
    }

    const data = (await fetchApi<TPrimer.ClientSessionBody>({
      endpoint: "/api/primer/",
      method: "POST",
      data: {
        order: sessionBody,
      },
    })) as TPrimer.ClientSessionBody

    setClientToken(data?.clientToken || null)
  }

  const initUniversalCheckout = async () => {
    // To avoid double rendering of checkout on dev, which causes UI defects.
    if (checkoutRef.current || !clientToken) {
      return
    } else {
      checkoutRef.current = true
    }
    // Experimenting a delay to see if this doesn't affect the checkout issues
    await sleep(400)

    try {
      const checkout = await Primer.showUniversalCheckout(clientToken, {
        // Specify the selector of the container element
        container: "#checkout-container",
        locale: locale === "en" && currencyCode === "USD" ? "en-US" : locale,
        // This is important to be off to avoid security flaw of showing user payment
        vault: {
          visible: false,
        },
        applePay: {
          captureBillingAddress: true,
          shippingOptions: {
            requiredShippingContactFields: [
              "emailAddress",
              "name",
              "phoneNumber",
            ],
          },
        },
        googlePay: {
          emailRequired: true,
          captureBillingAddress: true,
          shippingAddressParameters: {
            phoneNumberRequired: true,
          },
        },
        style: {
          input: {
            base: {
              background: "#fff",
              borderColor: "#e4e4e7",
              focus: {
                borderColor: "#a3a3a8",
              },
            },
          },
          submitButton: {
            base: {
              background: "rgb(22 163 74)",
            },
          },
        },

        /**
         * When the checkout flow has been completed, you'll receive
         * the successful payment via `onCheckoutComplete`.
         * Implement this callback to redirect the user to an order confirmation page and fulfill the order.
         */
        onCheckoutComplete() {
          onCheckoutComplete &&
            onCheckoutComplete({ method: paymentMethod.current })
        },
        /**
         * This is used to figure out what payment method the user is using to pay
         * @param data is just a `PaymentMethodType`
         * @param handler has methods to continue or abort the payment method
         */
        onBeforePaymentCreate(data, handler) {
          paymentMethod.current = data.paymentMethodType ?? null
          handler.continuePaymentCreation()
        },
        /**
         * When the checkout flow has been fail, you'll receive
         * the error via `onCheckoutFail`
         */
        onCheckoutFail(error, { payment }, handler) {
          console.error("Checkout Fail", error, payment)
          handler?.showErrorMessage(
            "Your payment could not be processed. Please try again with another payment method."
          )
          trackAction("payment_failed", { clientToken, error })
          onCheckoutFail && onCheckoutFail(error, payment)
        },
      })

      setUniversalCheckout(checkout)
    } catch (error) {
      console.error("Checkout Error!", error)
    }
  }

  useEffect(() => {
    // We need to keep clientToken for control flow.
    if (!clientToken) {
      createPrimerSession()
    }
    // Create checkout when clientToken is ready and just once.
    else if (clientToken && !universalCheckout) {
      initUniversalCheckout()
    }
    // Update session when cart changes (coupon and gift card)
    /*
    TODO: Experimenting disabling this for a test
    else if (clientToken && universalCheckout) {
      fetchApi("/api/primer/", {
        order: sessionBody,
        update: true,
      })
    }
    */

    // Since the callbacks and locale are now internal to Primer, changing them after won't work, so we ignore.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientToken, universalCheckout, sessionBody])

  return {
    getPrimerSessionBody,
    clientToken,
    universalCheckout,
  }
}
