import React, { useRef, useEffect, useState } from "react"
import { useMapsLibrary } from "@vis.gl/react-google-maps"

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void
  render: (ref: React.RefObject<HTMLInputElement>) => React.ReactNode
}

export const Autocomplete = ({ onPlaceSelect, render }: Props) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const places = useMapsLibrary("places")

  useEffect(() => {
    if (!places || !inputRef.current) return

    const options = {
      fields: ["address_components"],
    }

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
  }, [places])

  useEffect(() => {
    if (!placeAutocomplete) return

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace())
    })
  }, [onPlaceSelect, placeAutocomplete])

  return (
    <div className="autocomplete-container">
      {inputRef ? render(inputRef as React.RefObject<HTMLInputElement>) : null}
    </div>
  )
}
