import { Currency, Price, useI10n } from "@local/i10n"
import { SlotOutput, SlotPricesTable } from "./fetchers"
import { Counter } from "./Counter"
import { useCallback, useEffect, useId, useMemo, useState } from "react"
import type { CartItem } from "@local/product-option-manager/types"
import {
  generateCartId,
  getCurrency,
  useMultiCartStore,
  useProducts,
} from "@local/product-option-manager"
import { Payload } from "@local/payload-client/src/types"
import { ActionButtons } from "./ActionButtons"
import { Product } from "./OptionsSelector/Product"
import { AddQuantity } from "./OptionsSelector/AddQuantity"
import { useCart } from "@local/cart/src/useCart"
import { trackAction, useTracking } from "@local/tracking/src/trackActions"
import { classNames } from "@local/utils"
import type { UpsellStage } from "./useUpsell"
import { PreOrder } from "@local/checkout/src/components/PreOrder"
import * as Sentry from "@sentry/nextjs"
import Cookies from "js-cookie"
import { useRouter } from "next/navigation"

interface Props {
  isLoading: boolean
  isValidMethod: boolean
  modalRef?: React.RefObject<HTMLDivElement | null>
  setShowOrder?: () => void
  setStage: (stage: UpsellStage) => void
  slotData: SlotOutput
  slotPrices: SlotPricesTable
  slug?: string | null
  stage: 1 | 2 | 3 | null
}

const TIMER_MINUTES = 10

export const UpsellComponent = ({
  isLoading,
  isValidMethod,
  modalRef,
  setShowOrder,
  setStage,
  slotData,
  slotPrices,
  stage,
  slug,
}: Props) => {
  const router = useRouter()
  const { currencySymbol, currencyCode, country: countryCode } = useI10n()
  const {
    carts: multiCart,
    initCart,
    addItem,
    removeItem,
    updateItem,
  } = useMultiCartStore()

  const {
    getAvailableVariant,
    getInitialCart,
    getProductOptions,
    hasVariantStock,
    productMap,
  } = useProducts({
    mainProductId: slotData.products[0].id,
    products: slotData.products,
    currency: getCurrency(currencyCode as Currency),
    region: countryCode,
    ignoreSearchParams: true,
  })

  const localCart = useCart()
  const { trackActionBuffered } = useTracking()

  const uid = useId()
  const [quantity, setQuantity] = useState(1)
  const [carouselSelectedItem, setCarouselSelectedItem] = useState(0)
  const [currentProductId, setCurrentProductId] = useState(
    slotData.products[0].id
  )
  const [actionClicked, setActionClicked] = useState(false)

  // Added a small fix to load the second product correctly
  useEffect(() => {
    setCurrentProductId(slotData.products[0].id)
  }, [slotData])

  const slotPrice = useMemo(() => {
    return {
      price: slotPrices.prices.get(currentProductId)?.price as number,
      discount: slotPrices.discount,
      origPrice: slotPrices.prices.get(currentProductId)?.origPrice as number,
    }
  }, [currentProductId, slotPrices])

  const cartId = useMemo(() => generateCartId(), [])

  const stylesMap = new Map<Payload.Product["id"], Payload.Product>()
  if (slotData?.products && slotData.products.length) {
    slotData.products.forEach((style) => {
      const product = productMap.get(style.id)
      if (!product) return
      stylesMap.set(product.id, product)
    })
  }

  const updateLocalCart = useCallback(() => {
    const cart = multiCart.get(cartId)
    if (cart && cart.items && cart.items.length > 0) {
      const items = [...cart.items]
      const localCartItems = items.map((ci) => {
        const cartItem = { ...ci }
        const {
          preOrder,
          productId,
          productName,
          quantity,
          sku,
          variantId,
          variantName,
          image,
        } = cartItem

        const price = slotPrices.prices.get(cartItem.productId)?.price as number
        return {
          preOrder,
          productId,
          productName,
          quantity,
          sku,
          slug: "none",
          variantId,
          variantName,
          image,
          prices: [
            {
              min: 0,
              value: price,
            },
          ],
        }
      })
      localCart.replaceItems({
        channel: slug,
        items: [...localCartItems],
        currency: currencyCode,
        // isAnchor: true,
        trackActionBuffered,
        isUpsell: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartId, multiCart, slotPrices.prices, currencyCode, localCart])

  useEffect(() => {
    const gotoOrder = async () => await router.push(`/order/confirm/`)

    if (localCart.items.length <= 0 && localCart.validateChannel(slug)) {
      updateLocalCart()
    } else if (
      localCart.items.length <= 0 &&
      !localCart.validateChannel(slug)
    ) {
      Cookies.remove("show-upsell-modal")
      document.body.style.overflow = "auto"
      gotoOrder()
        .then(() => {})
        .catch(() => router.push(`/order/confirm/?blank=true`))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCart, updateLocalCart, slug])

  useEffect(() => {
    // FIXME both of these should come from backend with the right prices and not be hardcoded
    let initialCart = getInitialCart()

    if (!initialCart || initialCart?.length <= 0) {
      // If  initialCart is empty, jump one stage. This will read as "upsell denied"
      // TODO: make a stage that will show "in case of fail..."
      console.error("initialCart is empty - OOS item in upsell")
      Sentry.captureException(
        "Upsell error: initialCart is empty - OOS item in upsell"
      )
      setStage(3)

      initialCart = getInitialCart()
    }

    const parsedInitialCart = initialCart.map((item) => {
      return {
        ...item,
        price: slotPrices.prices.get(item.productId)?.price as number,
      }
    })

    const products = Array.from(productMap.values())

    const parsedProducts = products.map((product) => {
      return {
        ...product,
        prices: [
          {
            minQuantity: 0,
            price: slotPrices.prices.get(product.id)?.price as number,
            id: "",
          },
        ],
      }
    })
    localCart.reset(false)
    initCart(
      cartId,
      parsedProducts,
      parsedInitialCart,
      getCurrency(currencyCode as Currency)
    )
    updateLocalCart()
    setQuantity(1)
    // caution: at some point refreshing with slotData was slowly crashing the application without warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyCode, countryCode, slotData, stage])

  const handleChange = (cartItem: CartItem) => {
    setCurrentProductId(cartItem.productId)
    updateItem(cartId, cartItem)

    updateLocalCart()
    trackAction("upsell_clicked", {
      currency: currencyCode as Currency,
      discount: slotPrices.discount / 100, // Need to be float
      item_id: cartItem.variantId ?? cartItem.productId,
      item_name: cartItem.productName,
      item_variant: cartItem.variantName,
      price: cartItem.price, // Need to be integer
      quantity: cartItem.quantity,
      upsell_id: slotData.upsellId,
      upsell_slot: stage,
      upsell_version: slotData.version,
    })

    // TODO This is some tracking stuff needed
    // trackFbq("ViewContent", {
    //   currency,
    //   content_name: cartItem.productName,
    //   content_ids: cartItem.variantId ?? cartItem.productId,
    //   content_type: "product",
    // })
    // trackTtq("ViewContent", {
    //   contents: [
    //     {
    //       content_id: cartItem.variantId ?? cartItem.productId,
    //       content_type: "product",
    //       content_name: cartItem.productName,
    //     },
    //   ],
    // })
  }

  const handleQuantityChange = (quantity: number) => {
    setQuantity(quantity)
    const cart = multiCart.get(cartId)

    if (cart && cart?.items.length && cart.items.length > quantity) {
      for (let i = cart.items.length; i > quantity; i--) {
        removeItem(cartId, cart.items[i - 1].cartKey)
      }
    }

    if (cart && cart?.items.length && cart.items.length < quantity) {
      for (let i = cart.items.length; i < quantity; i++) {
        const item = getAvailableVariant(
          cart.items[cart.items.length - 1].productId,
          cart.items[0].options
        ) as Omit<CartItem, "cartKey">
        addItem(cartId, item)
      }
    }

    updateLocalCart()
  }

  const isLastOffer = stage === 2 || stage === 3

  return (
    <>
      <h2 className="w-3/4 mx-auto my-2 text-xl font-bold text-center">
        You&apos;ve paid for your order
      </h2>
      {isLastOffer ? "LAST CHANCE: Get Exclusive Discounts Today Only" : ""}
      <section className="container px-2 mx-auto mt-4 -mb-4 text-center rounded-lg bg-gray-50">
        <header
          className={classNames(
            "grid items-center grid-cols-1 gap-2 p-3 text-xl font-semibold align-middle rounded-xl sm:grid-cols-2 shadow-lg text-white",
            isLastOffer
              ? "bg-[#F65900] !text-orange-50"
              : "bg-green-600 !text-green-50"
          )}
        >
          <h3 className="text-xl font-display drop-shadow-sm">
            ONE TIME OFFER: Add{" "}
            <span className="text-xl font-bold text-white">
              {slotData?.products[0].name}
            </span>{" "}
            to your order with{" "}
            <span className="text-2xl font-bold text-white underline drop-shadow-sm">
              {slotPrices.discountType === "percent"
                ? `${slotPrices.discount.toFixed(0)}%`
                : `${currencySymbol}${slotPrices.discount}`}{" "}
              OFF!
            </span>
          </h3>
          <div>
            <div className="flex flex-row justify-center gap-2 my-2 text-lg text-white drop-shadow-sm">
              <span>🔥</span>
              <span>Hurry, this offer is only available for</span>
              <span>🔥</span>
            </div>
            <p className="flex flex-row items-center justify-center p-1 mb-0.5 py-2 text-xl text-black bg-white rounded-xl">
              <Counter minutes={TIMER_MINUTES} key={`timer-${stage}`} />
            </p>
          </div>
        </header>
        <div className="grid justify-center grid-cols-1 gap-4 p-4 mt-4 align-middle">
          <div className="text-center">
            <div>
              <div className="flex flex-col sm:flex-col-reverse">
                <h3 className="mb-2 text-3xl font-bold">
                  {slotData.products.find((p) => p.id === currentProductId)
                    ?.name ?? slotData?.title}
                </h3>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: (slotData?.description &&
                  slotData?.description?.length > 0
                    ? slotData?.description
                    : slotData.products[0]?.shortDescription) as string,
                }}
              />
            </div>
          </div>
          <div>
            <div>
              <p className="text-gray-500 uppercase">
                Was <Price value={slotPrice.origPrice} />
              </p>
              <p className="mb-4 text-2xl font-bold uppercase">
                Now only <Price value={slotPrice.price} />
              </p>
            </div>
            <div
              style={{
                pointerEvents: isLoading || actionClicked ? "none" : "auto",
                filter: isLoading || actionClicked ? "grayScale(1)" : "none",
                opacity: isLoading || actionClicked ? 0.5 : 1,
              }}
            >
              {multiCart.get(cartId)?.items && slotData
                ? multiCart.get(cartId)?.items.map((item, i) => (
                    <div
                      key={`upsell-cart-item-${item.productId}-${i}`}
                      className={classNames(
                        "bg-gradient-to-b from-gray-200 to-gray-100",
                        i === 0 ? "mt-4" : "",
                        "py-4 px-10 sm:px-5",
                        "-ml-4 -mr-4 sm:mx-0"
                      )}
                    >
                      <Product
                        carouselSelectedItem={carouselSelectedItem}
                        checkVariantStock={hasVariantStock}
                        getOptions={getProductOptions}
                        handleChange={handleChange}
                        handleQuantityChange={handleQuantityChange}
                        index={i}
                        item={item}
                        key={`options-selector-upsell-${uid}-${i}`}
                        quantity={quantity}
                        setCarouselSelectedItem={setCarouselSelectedItem}
                        slotData={slotData}
                        slotPrices={slotPrices}
                        stylesMap={stylesMap}
                      />
                      {localCart.items.length > 0 && (
                        <div className="mb-3 text-gray-600">
                          <PreOrder date={localCart.items[0].preOrder} />
                        </div>
                      )}
                    </div>
                  ))
                : null}

              <AddQuantity
                quantity={quantity}
                handleChange={handleQuantityChange}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="box-border w-full mx-auto z-[9999]">
        <div className="box-border w-full px-2 bg-white rounded-b-md sm:px-6">
          <ActionButtons
            cartId={cartId}
            clicked={actionClicked}
            isLoading={isLoading}
            isValidMethod={isValidMethod}
            localCart={localCart}
            modalRef={modalRef}
            multiCart={multiCart}
            quantity={quantity}
            setClicked={setActionClicked}
            setShowOrder={setShowOrder}
            setStage={setStage}
            slotData={slotData}
            slotPrices={slotPrices}
            slug={slug}
            stage={stage}
          />
        </div>
      </div>
    </>
  )
}
